import { useAuthStore } from "~~/store/auth";

export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore();

  if (
    useCookie("token").value &&
    from.name === "login" &&
    authStore.accountLevelString !== "disabled"
  ) {
    return navigateTo("/");
  }
});
